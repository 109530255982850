.Accordion {
    border-bottom: 1px solid #707070;
    padding: 35px 10px;
    color: #003DA6;
    text-align: left;
    font-size: 20px;
    margin-top: 30px ;
}

.AccordionHeader {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Accordion li{
    text-align: left;
    font-size: 16px;
}

.AccordionBody {
    margin-top: 10px;
    padding: 10px 20px;
    color: #707070;
    font-size: 16px;
}

.Expander {
   font-size: larger;
   font-weight: lighter;
}

.Icon {
    height: 100%;
    width: 16px;
}

.Accordion span {
    font-size: medium;
    font-weight: lighter;
}

@media (min-width: 768px){
    .Accordion {
        font-size: 30px;
    }

    .Icon {
        width: auto;
    }
}
