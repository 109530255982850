.Header {
    height: 87px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #F9F9F9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 26px;
    box-sizing: border-box;
    z-index: 100;
}

.Logo {
    width: auto;
    display: block;
}

.AccoladeLogo {
    height: 40px;
}

.HyattLogo, .RocketSoftwareLogo, .L3Harris {
    height: 60px;
}

.KindredLogo, .EnerconLogo {
    height: 40px;
}

.AAALogo {
    height: 70px;
}

.PaypalLogo, .FafLogo {
    height: 20px;
}

.BeaconLogo {
    height: 35px;
}

.AmplifyLogo{
    height: 20px;
}

.HebrewLogo{
    height: 28px;
}

.DefaultLogo, .LermanHoldingsLogo {
    height: 40px;
}

.PeratonLogo, .SevenElevenLogo {
    height: 4vw;
    max-height: 55px;
}

@media (min-width: 768px){
    .Header {
        height: 185px;
        padding: 0 133px;
    }

    .AccoladeLogo {
        height: 84px;
    }

    .KindredLogo {
        height: 75px;
    }

    .AAALogo {
        height: 120px;
    }

    .AmplifyLogo, .LermanHoldingsLogo {
        height: 60px;
    }

    .PaypalLogo, .FafLogo {
        height: 50px;
    }

    .BeaconLogo{
        height: 75px;
    }

    .HebrewLogo{
        height: 70px;
    }

    .DefaultLogo {
        height: 84px;
    }
  }
