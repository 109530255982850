.ImgBanner {
    margin-top: 90px;
    align-items: center;
}
.ImgBanner h2 {
  font-weight: normal;
  font-size: 35px;
}
.ImgBanner h3 {
    font-size: 15px;
    font-weight: 100;
  }
.ImgBanner p, h3 {
    line-height: 1.3;
}

.PhoneNumberLink {
    letter-spacing: 3.2px;
    font-weight: bold;
}

.Title {
    color: #ffffff;
    font-size: 15px;
    background-image: url(../../assets/images/Group\ 935@2x.png);
    background-size: cover;
    padding:78px 10px 104px 29px;
    box-sizing: border-box;
}
.Title .p {
    max-width: 287px;
}

.Text {
    font-size: 10px;
}

.ArrowIcon {
    color: white;
    height: 1rem;
    width: auto;
    transition: color .15s;
}

.ArrowIcon:hover {
    color: #1079BC;
}

.ArrowLottie {
    cursor: pointer;
}

.ArrowLottie svg {
    width: 1.8rem !important;
    height: 1.8rem !important;
    transform: translate3d(0px, 5px, 0px) rotate(-90deg) !important;
}

@media (min-width: 768px){
    .ImgBanner {
        margin-top: 185px;
        min-height: 680px;
        background-image: url(../../assets/images/Group\ 935@2x.png);
        background-size: cover;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0px 208px 0px 138px;
    }

    .ImgBanner h2 {
      font-weight: 100;
      font-size: 65px;
    }

    .ImgBanner h3 {
        font-size: 30px;
      }

    .ImgBanner p, h3 {
        line-height: 1.6;
    }

    .Title {
        width: 590px;
        font-size: 30px;
        background-image: none;
        background-size: none;
        padding: initial;
    }

    .Title .p{
        max-width: initial;
    }

    .container {
        width: 380px;
    }

    .Text {
        font-size: initial;
        margin-top: 42px;
    }
}