.Footer {
    height: 107px;
    width: 100%;
    bottom: 0;
    left: 0;
    color: white;
    background-color: #003DA6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 103px;
    box-sizing: border-box;
}

.ServicePhone{
    display: flex;
    justify-content: space-between;
}

.ServicePhone img {
    margin-right: 20px;
}

.PhoneIconLink {
   margin-right: 0.5rem;
}

.PhoneIconLink:hover path {
    stroke: #1079BC;
}

.AccoladeLogo {
    height: 69px;
    width: auto;
}

@media (min-width: 768px){
    .Footer {
        height: 130px;
        padding: 0 20px;
        margin-top: 150px;
    }
}
