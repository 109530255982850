body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, input, textarea, button {
  font-family: 'Open Sans';
}

a {
  color: #1079bc;
  text-decoration: none;
  /* regular */
  font-weight: 400;
  transition: color .15s;
}

a:hover {
  color: #1079bc !important;
  /*  semi-bold */
  font-weight: 600;
}