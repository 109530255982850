.Global {
    margin: auto;
    margin-top: 50px;
    color: #40464B;
    text-align: center;
    box-sizing: border-box;
  }
  
  .Global h1 {
    color: #003DA6;
    font-weight: 100;
    font-size: 30px;
    margin-bottom: 35px;
  }
  
  .Global p {
      text-align: left;
      font-size: 16px;
    }
  
  .Global span {
      font-weight: bold;
  }
  
  .PhoneLink, .PhoneLink:hover {
      color: white !important;
  }
  
  @media (min-width: 768px){
    .Global {
      width: 80%;
    }
    
    .Global h1 {
      font-size: 50px;
      margin-bottom: 60px;
    }
    
    .Global p {
        font-size: 20px;
      }
  }