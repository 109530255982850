.Card {
    background-color: #FFFFFF;
    box-shadow: 5px 5px 12px #00000029;
    opacity: 1;
    padding: 10px 40px 30px 29px;
    color: #40464B;
    margin-top: 25px;
    margin-bottom: 38px;
}

@media (min-width: 768px){
    .Card {
        padding: 0px 50px 50px 55px;
        margin-top: 90px;
        margin-bottom: initial;
    }
}