.Banner {
    background-color: #1079BC;
    padding: 48px 29px 48px 29px;
    color: #ffffff;
}
.Banner h2{
    font-size: 25px;
}
.Banner p{
    font-size: 16px;
}
.Banner a{
    color: white;
    text-decoration: underline;
}
.Banner a:hover {
    color: white !important;
  }

  @media (min-width: 768px){
    .Banner {
        padding: 30px 138px;
    }
    .Banner h2{
        font-size: 40px;
        font-weight: 600pt;
    }
    .Banner p{
        font-size: 20px;
    }
  }